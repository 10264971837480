import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import "./global.css";
import App from "./App";
import Head from "./components/layout/head";
import Headline from "./components/layout/headline";
import { StateMoonBaseContextProvider } from "./context/moonBaseContractContext";
import "@rainbow-me/rainbowkit/styles.css";
import { darkTheme } from "@rainbow-me/rainbowkit";
import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { sepolia, mainnet } from "wagmi/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import Slidebar from "./components/slidebar/Slidebar";
import Endline from "./components/endline/endline";
import { MoonBaseLiquidityContractProvider } from "./context/moonBaseLiquidityContractContext";
import { LiquidityContractProvider } from "./context/liquidityHelperContext";

const { chains, publicClient } = configureChains(
  [mainnet],
  [
    alchemyProvider({ apiKey: "y5GcR0NBerm6Zg38XyTQxyO9FMw_TY4I" }),
    publicProvider(),
  ]
);

const { connectors } = getDefaultWallets({
  appName: "moonbase",
  projectId: "rlQ5sTxD1KG_dzYmZUjWjgS6ME9Nn8D1",
  chains,
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider
        coolMode
        modalSize="compact"
        chains={chains}
        theme={darkTheme()}
      >
        <StateMoonBaseContextProvider>
          <MoonBaseLiquidityContractProvider>
            <LiquidityContractProvider>
              <div className="background" ></div>
              <Head />
              <Headline />

              <div className="flex justify-center lg:justify-between items-centre px-0 lg:px-10  mx-auto max-w-[1440px] ">
                <style jsx>{`
        ::-webkit-scrollbar {
          width: 4px !important;
          background-color: black;
        }

        ::-webkit-scrollbar-thumb {
          background-color: #6142ff;
        }
      `}</style>
                <Slidebar />
                <App />
              </div>
              <div className="  mx-auto max-w-[1440px]">
                <Endline />
              </div>
            </LiquidityContractProvider>
          </MoonBaseLiquidityContractProvider>
        </StateMoonBaseContextProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  </BrowserRouter>
);
