import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Marquee from "react-fast-marquee";
import dashhead from "../../assets/videos/moonbase.gif";

const SkeletonLoadingHeadline = () => {
  // Define the number of skeleton items to show
  const skeletonCount = 6;
  const skeletonItems = Array.from({ length: skeletonCount });
  //this section is for skeleton data
  return (
    <div className="w-[100%] mx-auto max-w-[1440px] py-2  flex items-center justify-center flex-col xl:px-10 ">
      <Marquee className="flex w-[90%] lg:h-[95px] px-0 border-2 border-[#6142FF] items-center justify-center space-x-4 bg-[#000]">
        {skeletonItems.map((_, index) => (
          <div key={index} className="flex items-center gap-3 space-x-4">
            <div className="w-8 h-8 bg-gray-300 rounded-full"></div>
            <div className="h-64 bg-gray-300 rounded-md w-69"></div>
            <div className="w-32 h-8 bg-gray-300 rounded-md"></div>
          </div>
        ))}
      </Marquee>
    </div>
  );
};

const Headline = () => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation(); // Get the current location (URL)

  // Simulate loading data here (you can replace this with actual data fetching)
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false); // Set isLoading to false after the data is fetched (simulated here using setTimeout)
    }, 1000);
  }, []);

  // You can map the texts array based on the route here or customize it as per your requirements
  const textsMap = {
    "/dashboard": [
      "DASHBOARD",
      "MOONVAULT",
      "DASHBOARD",
      "MOONVAULT",
      "DASHBOARD",
      "MOONVAULT",
      "DASHBOARD",
      "MOONVAULT",
      "DASHBOARD",
      "MOONVAULT",
      "DASHBOARD",
      "MOONVAULT",
      "DASHBOARD",
      "MOONVAULT",
      "DASHBOARD",
      "MOONVAULT",
      "DASHBOARD",
      "MOONVAULT",
      "DASHBOARD",
      "MOONVAULT",
      "DASHBOARD",
      "MOONVAULT",
      "DASHBOARD",
      "MOONVAULT",
    ],
    "/claim": [
      "REWARD",
      "MOONVAULT",
      "REWARD",
      "MOONVAULT",
      "REWARD",
      "MOONVAULT",
      "REWARD",
      "MOONVAULT",
      "REWARD",
      "MOONVAULT",
      "REWARD",
      "MOONVAULT",
      "REWARD",
      "MOONVAULT",
      "REWARD",
      "MOONVAULT",
      "REWARD",
      "MOONVAULT",
      "REWARD",
      "MOONVAULT",
      "REWARD",
      "MOONVAULT",
      "REWARD",
      "MOONVAULT",
      "REWARD",
      "MOONVAULT",
      "REWARD",
      "MOONVAULT",
      "REWARD",
      "MOONVAULT",
      "REWARD",
      "MOONVAULT",
      "REWARD",
      "MOONVAULT",
      "REWARD",
      "MOONVAULT",
    ],
    "/incinerator": [
      "INCINERATOR",
      "MOONVAULT",
      "INCINERATOR",
      "MOONVAULT",
      "INCINERATOR",
      "MOONVAULT",
      "INCINERATOR",
      "MOONVAULT",
      "INCINERATOR",
      "MOONVAULT",
      "INCINERATOR",
      "MOONVAULT",
      "INCINERATOR",
      "MOONVAULT",
      "INCINERATOR",
      "MOONVAULT",
      "INCINERATOR",
      "MOONVAULT",
      "INCINERATOR",
      "MOONVAULT",
      "INCINERATOR",
      "MOONVAULT",
      "INCINERATOR",
      "MOONVAULT",
      "INCINERATOR",
      "MOONVAULT",
      "INCINERATOR",
      "MOONVAULT",
      "INCINERATOR",
      "MOONVAULT",
    ],
  };

  const texts = textsMap[location.pathname] || ["DASHBOARD",
    "MOONVAULT",
    "DASHBOARD",
    "MOONVAULT",
    "DASHBOARD",
    "MOONVAULT",
    "DASHBOARD",
    "MOONVAULT",];

  if (isLoading) {
    return <SkeletonLoadingHeadline />;
  }

  return (
    <div className="w-[100%] mx-auto max-w-[1440px] py-2 px-0 lg:px-8 flex items-center justify-center flex-col xl:px-10">
      <Marquee loop={0} className="flex w-[90%] lg:py-[8px] lg:h-[95px] px-0 border-2 border-[#6142FF] items-center justify-center space-x-4  bg-[#000] overflow-y-hidden">
        {texts.map((text, index) => (
          <div key={index} className="flex items-center h-full gap-3 px-5 space-x-4">
            <img src={dashhead} alt="/" width={100} height={60} />
            <h1 className="cursor-pointer mainSubHeading">{text}</h1>
          </div>
        ))}
      </Marquee>
    </div>
  );
};

export default Headline;
