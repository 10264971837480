import Web3 from "web3";
import { createContext, useContext } from "react";
import { useAccount, useWalletClient } from "wagmi";
import { liquidityAddress } from "../web3/contractAddrress";
import moonBaseLiquidityABI from "../web3/moonBaseLiquidityABI";

const LiquidityContext = createContext();

export const LiquidityContractProvider = ({ children }) => {
    const { address } = useAccount()
    const { data: walletClient } = useWalletClient();

    const web3 = new Web3(walletClient);

    const liquidityContractInstance = new web3.eth.Contract(moonBaseLiquidityABI, liquidityAddress);

    const AddLiquidityEthers = async (mvtAmount, ethAmount) => {
        try {
            /* eslint-disable no-undef */
            const mvtWeiValue = BigInt(mvtAmount * (10 ** 18));
            const ethWeiValue = BigInt(ethAmount * (10 ** 18));

            const result = await liquidityContractInstance.methods.addVaultLiquidity(mvtWeiValue.toString())
                .send({ from: address, value: ethWeiValue.toString() })
            return result;
        } catch (err) {
            throw (err);
        }
    }

    return (
        <LiquidityContext.Provider
            value={{
                AddLiquidityEthers,
            }}
        >
            {children}
        </LiquidityContext.Provider>
    );
};

export const LiquidityHelperContract = () => useContext(LiquidityContext);
