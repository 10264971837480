import React from 'react'

const Endline = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className=" flex flex-col items-center justify-center w-[100%] h-auto overflow-y-hidden pb-[8px] ">
    <h2 className="w-[94%] mt-[1px] pt-[10px] flex justify-between items-center">
      <h className="font-medium text-white text-md">MOONVAULT DAPP</h>
      <h className="font-medium text-white text-md">{currentYear}</h>
    </h2>
  </div>
  )
}

export default Endline;
