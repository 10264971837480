import { useContext, createContext, useState, useEffect } from "react";
import Web3 from "web3";
import { useAccount } from "wagmi";
import { ethers } from "ethers";
import moonBaseContractABI from "../web3/moonBaseContractABI";
import { liquidityAddress, moonBaseContractAddress } from "../web3/contractAddrress";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useWalletClient } from "wagmi";

// Creating a new context
const StateMoonBaseContext = createContext();

export const StateMoonBaseContextProvider = ({ children }) => {
  const { address } = useAccount();
  const [chainId, setChainId] = useState(null);
  const [showNav, setShowNav] = useState(false);
  const [allowance, setAllowance] = useState(0);
  const [loadingState, setLoadingState] = useState("");
  const [ethDollarRate, setEthDollarRate] = useState(0);
  const [moonbaseBalance, setMoonBaseBalance] = useState(0);
  const [moonbaseDollarRate, setMoonbaseDollarRate] = useState(0);
  const [moonbaseMarketCap, setMoonbaseMarketCap] = useState(0);

  const notify = (msg) => {
    toast.success(msg, {
      autoClose: 2000,
      draggable: false,
      closeOnClick: true,
      progress: undefined,
      pauseOnHover: false,
      hideProgressBar: true,
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const errorNotify = (msg) => {
    toast.error(msg, {
      autoClose: 2000,
      draggable: false,
      closeOnClick: true,
      progress: undefined,
      pauseOnHover: false,
      hideProgressBar: true,
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const { data: walletClient } = useWalletClient();
  const web3Pro = walletClient
    ? walletClient
    : "https://mainnet.infura.io/v3/7a6a4b893bfe4325aed8a527215570f6";
  // : "https://sepolia.infura.io/v3/72b85b515f1a4c98b2667acf92b6276b";

  const web3 = new Web3(web3Pro);
  const moonBaseContractInstance = new web3.eth.Contract(
    moonBaseContractABI,
    moonBaseContractAddress
  );

  useEffect(() => {
    async function fetchMoonbaseDollarRate() {
      try {
        const response = await fetch(
          "https://api.geckoterminal.com/api/v2/networks/eth/pools/0xb057a78c16915172090dbcd71390195aa973d65b"
        );
        const data = await response.json();
        setMoonbaseDollarRate(data?.data?.attributes?.base_token_price_usd);
        setMoonbaseMarketCap(data?.data?.attributes?.fdv_usd);
      } catch (error) {
        return error;
      }
    }

    async function fetchEthDollarRate() {
      try {
        const response = await fetch(
          "https://fetchtoken.app/alert/get_price_Felix_eth",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              symbol: "ETH",
            }),
          }
        );
        const data = await response.json();
        setEthDollarRate(data[0]?.price);
      } catch (error) {
        return error;
      }
    }

    fetchEthDollarRate();
    fetchMoonbaseDollarRate();
  }, []);


  useEffect(() => {
    async function getChainId() {
      if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const network = await provider.getNetwork();

        setChainId(network?.chainId);
      }
    }
    getChainId();
  }, []);

  async function getChainIdLatest() {
    if (window.ethereum) {
      window.location.reload();
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const network = await provider.getNetwork();

      setChainId(network?.chainId);
    }
  }

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("chainChanged", getChainIdLatest);
    }
  }, []);

  const getCirculatingSupply = async () => {
    try {
      const result = await moonBaseContractInstance.methods.totalSupply().call();

      return result;
    } catch (error) {
      return error;
    }
  };

  const getBurned = async () => {
    try {
      const result = await moonBaseContractInstance.methods.totalBurned().call();
      notify();
      return result;
    } catch (error) {
      throw error;
    }
  };

  const getLpReward = async () => {
    try {
      const result = await moonBaseContractInstance.methods.totalInfuseLPAdded().call();

      return result;
    } catch (error) {
      throw error;
    }
  };

  const getReflection = async (tokenAddress) => {
    try {
      const result = await moonBaseContractInstance.methods.getTotalDividendsDistributed(tokenAddress).call();

      return result;
    } catch (error) {
      throw error;
    }
  };

  const getAccountTokens = async (walletAddress, tokenAddress) => {
    try {
      const result = await moonBaseContractInstance.methods.getAccountDividendsInfo(walletAddress, tokenAddress).call();

      return result;
    } catch (error) {
      throw error;
    }
  };

  const getMoonBaseBalance = async (wallet) => {
    try {
      const result = await moonBaseContractInstance.methods.balanceOf(wallet).call();
      return Number(result);
    } catch (error) {
      throw error;
    }
  };

  const publishClaim = async () => {
    try {
      const result = await moonBaseContractInstance.methods
        .claim()
        .send({ from: address });

      notify('Claimed Successfully');
      setTimeout(() => {
        window.location.reload()
      }, 6000);

      return result;
    } catch (err) {
      errorNotify('Something went wrong');
      throw err;
    }
  };

  const claimRewardAsBenificiary = async (receiverAddress) => {
    try {
      const result = await moonBaseContractInstance.methods
        .claimForBeneficiary(receiverAddress)
        .send({ from: address });
      notify('Claimed Successfully');
      return result;
    } catch (error) {
      errorNotify('Something went wrong');
      throw error;
    }
  };

  const publishBurn = async (val) => {
    try {
      const result = await moonBaseContractInstance.methods
        // eslint-disable-next-line no-undef
        .burn(BigInt(val))
        .send({ from: address });
      notify('Burned Successfully');

      return result;
    } catch (err) {
      errorNotify('Something went wrong');
      throw err;
    }
  };

  const getAllowance = async () => {
    try {
      const result = await moonBaseContractInstance.methods.allowance(address, liquidityAddress).call();
      return result;
    } catch (error) {
      throw error;
    }
  };

  /* eslint-disable no-undef */
  const UINT256_MAX = BigInt(2) ** BigInt(256) - BigInt(1);
  /* eslint-enable no-undef */

  const getApproval = async () => {
    try {
      const result = await moonBaseContractInstance.methods
        .approve(liquidityAddress, UINT256_MAX.toString())
        .send({ from: address });
      return result;
    } catch (error) {
      throw error;
    }
  };

  return (
    <StateMoonBaseContext.Provider
      value={{
        showNav,
        address,
        allowance,
        loadingState,
        ethDollarRate,
        moonbaseBalance,
        moonbaseMarketCap,
        moonbaseDollarRate,
        getBurned,
        setShowNav,
        getLpReward,
        errorNotify,
        getApproval,
        publishBurn,
        setAllowance,
        publishClaim,
        getAllowance,
        getReflection,
        setLoadingState,
        getAccountTokens,
        getMoonBaseBalance,
        setMoonBaseBalance,
        getCirculatingSupply,
        claimRewardAsBenificiary
      }}
    >
      {children}
    </StateMoonBaseContext.Provider>
  );
};

export const useMoonBaseStateContext = () => useContext(StateMoonBaseContext);
