import React from 'react';
import logo from '../../assets/images/logo.png';

const Loader = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
          {/* Background element with blur effect */}
          <div className="fixed inset-0 bg-black backdrop-blur-md opacity-90"></div>
          <div className="animate-moveUpDown  flex items-center justify-center ">
            <img src={logo} alt="logo-loader" className="w-[25%] h-[25%]" />
          </div>
        </div>
  );
};

export default Loader;
