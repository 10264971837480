import React from "react";
import styles from "./Button.module.css";

const MoonbaseButton = ({ label, variant }) => {
  const btnVariantClass = getVariantClass(variant);
  return (
    <a href="https://app.uniswap.org/explore/tokens/ethereum/0x220125af9da649e368813733ec8702a4145f996b" alt="" target="_blank" className={`${styles.button} ${btnVariantClass}`}>{label}</a>
  );
};

export default MoonbaseButton;

const getVariantClass = (variant) => {
  switch (variant) {
    case "solid":
      return styles.solid;
    case "outline":
      return styles.outline;
    case "lightSolid":
      return styles.lightSolid;
    default:
      return styles.default;
  }
};