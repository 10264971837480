import React, { createContext, useContext, useEffect, useState } from 'react';
import uniswapV2PairABI from '../web3/uniswapV2PairABI';
import { useAccount } from 'wagmi';
import Web3 from 'web3';
import { liquidityPairAddress, moonBaseContractAddress } from '../web3/contractAddrress';

const MoonBaseLiquidityContractContext = createContext();

export const MoonBaseLiquidityContractProvider = ({ children }) => {

    const [accountEthBalance, setAccountEthBalance] = useState();
    const [reserveMVT, setReserveMVT] = useState(0);
    const [reserveETH, setReserveETH] = useState(0);

    // const web3 = new Web3('https://sepolia.infura.io/v3/72b85b515f1a4c98b2667acf92b6276b');
    const web3 = new Web3('https://mainnet.infura.io/v3/72b85b515f1a4c98b2667acf92b6276b');

    const uniswapPairContract = new web3.eth.Contract(uniswapV2PairABI, liquidityPairAddress);

    const { address } = useAccount()

    const getEthBalance = async () => {
        try {
            if (address) {
                const balanceInWei = await web3.eth.getBalance(address);
                // Convert the balance from Wei to Ether for readability
                const balanceInEth = web3.utils.fromWei(balanceInWei, 'ether');
                return balanceInEth;
            }
        } catch (error) {
            throw error;
        }
    };

    const fetchReserves = async () => {
        try {
            const token0 = await uniswapPairContract.methods.token0().call();
            const token1 = await uniswapPairContract.methods.token1().call();
            const reserves = await uniswapPairContract.methods.getReserves().call();
            if (token0.toLowerCase() === moonBaseContractAddress.toLowerCase()) {
                setReserveMVT(reserves._reserve0 / 1e18);
                setReserveETH(reserves._reserve1 / 1e18);
            } else if (token1.toLowerCase() === moonBaseContractAddress.toLowerCase()) {
                setReserveMVT(reserves._reserve1 / 1e18);
                setReserveETH(reserves._reserve0 / 1e18);
            } else {
                throw new Error('MVT token not found in this pair');
            }
        } catch (error) {
            throw error;
        }
    }
    useEffect(() => {
        fetchReserves();

        const interval = setInterval(() => {
            fetchReserves();
        }, 5000);

        return () => clearInterval(interval);
    }, []);


    return (
        <MoonBaseLiquidityContractContext.Provider
            value={{
                reserveMVT,
                reserveETH,
                accountEthBalance,
                getEthBalance,
                setAccountEthBalance,
            }}
        >
            {children}
        </MoonBaseLiquidityContractContext.Provider>
    );
};

export const useMoonBaseLiquidityContract = () => useContext(MoonBaseLiquidityContractContext);
