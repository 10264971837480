import React, { useEffect, useState } from "react";
import head from "../../assets/images/logo.png";
import Button from "../buttons/Button";
import MoonbaseButton from "../buttons/MoonbaseButton";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { useMoonBaseStateContext } from "../../context/moonBaseContractContext";
import { Link } from "react-router-dom";
import { useSwitchNetwork, useNetwork } from "wagmi";
import { disconnect } from "@wagmi/core";
import { useAccount } from "wagmi";
import styles from "./head.module.css";

const Head = () => {
  const account = useAccount();
  const { showNav, setShowNav, address, errorNotify } = useMoonBaseStateContext();
  const [currentChain, setCurrentChain] = useState("");
  const { chain } = useNetwork();
  const { chains, error, isLoading, pendingChainId, switchNetwork } =
    useSwitchNetwork({
      onSuccess(data) {
      },
    });
  // Function to handle the sidebar toggle
  const handleNav = () => {
    setShowNav(!showNav);
  };
  const handleShowToast = () => {


    errorNotify("Please connect Your Wallet");
  };

  useEffect(() => {
    if (!address) {
      handleShowToast();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {



    if (chain?.id !== 1 && account.isConnected === true) {
      errorNotify("Please Connect with Ethereum Network");
      disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account?.isConnected]);

  // getting chain id on run time

  useEffect(() => {
    setCurrentChain(chain?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chains]);
  return (
    <div className=" w-[100%] mx-auto max-w-[1440px] flex flex-col justify-center items-center bg-black ">
      <div className="w-[93%]  flex justify-between items-center  ">
        <div className="mt-2 ">
          <Link to={"/"}>
            <div className="flex gap-2">
              <img src={head} alt="Logo" width="30px" height="30px" />
              <div className="flex items-center">
                <h2
                  className={`${styles.navbarHeadingName} hidden sm:block tracking-widest font-bold text-[#e4e4e4]`}
                >
                  MOONVAULT
                </h2>
              </div>
            </div>
          </Link>
        </div>

        <div className="flex items-center gap-3 mt-3">
          <MoonbaseButton className="w-[80px] md:w-[40%]" label={"BUY $VAULT"} />
          <Button label={"CONNECT"} variant={"solid"} />

          <div onClick={handleNav} className="block text-[#6142FF] lg:hidden">
            {showNav ? <AiOutlineClose size={25} className="cursor-pointer" /> : <AiOutlineMenu size={25} className="cursor-pointer" />}
          </div>
        </div>
      </div>
      <div className="flex flex-col w-[100%] items-center mt-2">
        <span className="w-[100%]  h-[1px] bg-[#6142FF]"></span>
      </div>
    </div>
  );
};

export default Head;
