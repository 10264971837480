import React from "react";
import { NavLink } from "react-router-dom";
import twitter from "../../assets/icons/twitter.svg";
import teleg from "../../assets/icons/telegram.svg";
import circle from "../../assets/icons/medium.svg";
import { useMoonBaseStateContext } from "../../context/moonBaseContractContext";

const Slidebar = ({ children }) => {
  const { showNav, setShowNav } = useMoonBaseStateContext(); // Get the setShowNav function from the context

  const menuItem = [
    {
      path: "/dashboard",
      name: "DASHBOARD",
    },
    {
      path: "/claim",
      name: "REWARD CENTER",
    },
    {
      path: "/provision-center",
      name: "PROVISION CENTER",
    },
    {
      path: "/fuel-chamber",
      name: "FUEL STATION",
    },
  ];

  const handleTabClick = () => {
    // Close the sidebar on tab click for medium and small screens
    if (window.innerWidth <= 1024) {
      setShowNav(false);
    }
  };

  return (
    <div
      className={`${showNav ? "flex" : "hidden"
        } px-[9px] sm:px-0 lg:flex w-[100%] lg:w-[20%] xl:w-[18%] h-[400px] bg-black`}
    >

      <div
        className={`flex flex-col justify-evenly py-[2.1rem] subHeadingText px-[1.2rem]  mx-auto max-w-[1440px]  text-white w-[100%] xl:w-[100%]  border-[#6142FF] border-2 gap-[1rem] whitespace-nowrap mt-[7px] md:mt-0 `}
      >
        {menuItem?.map((item, index) => (
          <NavLink
            NavLink
            to={item.path}
            key={index}
            className="link"
            activeClassName="active" // This specifies the class to apply to the active link
            onClick={handleTabClick} // Call the handleTabClick function when a tab is clicked
          >
            <div className="hover:text-[#6142ff] text-center lg:text-left link_text">
              <h3 className="text-sm font-normal xl:text-lg">{item.name}</h3>{" "}
            </div>
          </NavLink>
        ))}
        <div className="flex w-[100%] gap-2 items-center justify-center lg:justify-center flex-wrap">
          <a href="https://x.com/MoonVaultETH" target="_blank" rel="noopener noreferrer">
            <img
              src={twitter}
              width={25}
              height={25}
              alt="twitter"
              className="object-cover"
            />
          </a>
          <a href="https://t.me/MoonVaultETH" target="_blank" rel="noopener noreferrer">
            <img
              src={teleg}
              width={25}
              height={25}
              alt="telegram"
              className="object-cover text-white "
            />
          </a>
          <a href="https://medium.com/@moonvaulteth" target="_blank" rel="noopener noreferrer">
            <img
              src={circle}
              width={25}
              height={25}
              alt="medium"
              className="object-cover"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Slidebar;
