import React, { lazy, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Loader from "../components/loader/Loader";
// Lazy loading for pages
const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"));
const Claim = lazy(() => import("../pages/claim/Claim"));
const Incinerator = lazy(() => import("../pages/incinerator/Incinerator"));
const ProvisionCenter = lazy(() =>
  import("../pages/provision-center/ProvisionCenter")
);

const MainRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="dashboard" />} />
      <Route
        path="/dashboard"
        element={
          <Suspense
            fallback={
              <div>
                <Loader />
              </div>
            }
          >
            <Dashboard />
          </Suspense>
        }
      />
      <Route
        path="/claim"
        element={
          <Suspense
            fallback={
              <div>
                <Loader />
              </div>
            }
          >
            <Claim />
          </Suspense>
        }
      />
      {/* Add more routes as needed */}
      <Route
        path="/fuel-chamber"
        element={
          <Suspense
            fallback={
              <div>
                <Loader />
              </div>
            }
          >
            <Incinerator />
          </Suspense>
        }
      />
      <Route
        path="/provision-center"
        element={
          <Suspense
            fallback={
              <div>
                <Loader />
              </div>
            }
          >
            <ProvisionCenter />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default MainRoutes;
